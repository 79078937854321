import { XandrAdvertiser } from 'api';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { appRoutes } from 'routes';
import { useUrlSearchParams } from 'utils';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { queryKeys } from 'api/queryKeys';

import { getXandrAdvertiser } from './queries';

export const useGetXandrAdvertiser = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return useMutation<XandrAdvertiser, any, any>({
    mutationKey: [queryKeys.xandrAdvertiser],
    mutationFn: ({ id, country }) =>
      getXandrAdvertiser({
        id,
        country,
      }),
    onSuccess: (xandrAdvertiserData) => {
      queryClient.setQueryData([queryKeys.xandrAdvertiser], xandrAdvertiserData);
    },
    onError: () => {
      if (pathname !== appRoutes.selectAdvertiser) {
        navigate(appRoutes.selectAdvertiser);
      }
    },
  });
};

export const useGetXandrAdvertiserWithCache = () => {
  const navigate = useNavigate();
  const { country, consumerSystemAdvertiserId } = useUrlSearchParams();

  const queryClient = useQueryClient();
  const xandrAdvertiserCacheData = queryClient.getQueryData<XandrAdvertiser>([
    queryKeys.xandrAdvertiser,
  ]);

  const { data, isLoading, isError } = useQuery({
    queryKey: [queryKeys.xandrAdvertiser],

    queryFn: () =>
      getXandrAdvertiser({
        id: consumerSystemAdvertiserId!,
        country: country!,
      }),

    enabled:
      !xandrAdvertiserCacheData &&
      Boolean(consumerSystemAdvertiserId) &&
      Boolean(country),
  });

  useEffect(() => {
    if (isError) {
      navigate(appRoutes.selectAdvertiser);
    }
  }, [isError, navigate]);

  return { data, isLoading };
};
