import { MediaAsset } from './mediaAssets';

export interface PagedResponse<T> {
  data: T;
  meta: {
    totalElements: number;
    pageSize: number;
    currentPage: number;
  };
}

export interface Sort<T> {
  field: T;
  order: 'asc' | 'desc';
}

export interface PagedRequest {
  sort?: string;
  page?: number;
  size?: number;
}

export interface PagedSearchRequest extends PagedRequest {
  searchField?: string;
  enabled?: boolean;
  refetchInterval?: number | false;
  onSettled?: (data?: PagedResponse<MediaAsset[]>, error?: any) => void;
}

export enum AdFormat {
  SEAMLESS_IMAGE = 'SEAMLESS_IMAGE',
  SEAMLESS_IMAGE_L = 'SEAMLESS_IMAGE_L',
  SEAMLESS_IMAGE_XL = 'SEAMLESS_IMAGE_XL',
  LEADGEN_SEAMLESS_IMAGE = 'LEADGEN_SEAMLESS_IMAGE',
  LEADGEN_SEAMLESS_IMAGE_L = 'LEADGEN_SEAMLESS_IMAGE_L',
  LEADGEN_SEAMLESS_IMAGE_XL = 'LEADGEN_SEAMLESS_IMAGE_XL',
  SEAMLESS_IMAGE_CROSS_DEVICE = 'SEAMLESS_IMAGE_CROSS_DEVICE',
  SEAMLESS_IMAGE_MINI = 'SEAMLESS_IMAGE_MINI',

  SEAMLESS_VIDEO = 'SEAMLESS_VIDEO',
  SEAMLESS_VIDEO_L = 'SEAMLESS_VIDEO_L',
  SEAMLESS_VIDEO_XL = 'SEAMLESS_VIDEO_XL',
  LEADGEN_SEAMLESS_VIDEO = 'LEADGEN_SEAMLESS_VIDEO',
  LEADGEN_SEAMLESS_VIDEO_L = 'LEADGEN_SEAMLESS_VIDEO_L',
  LEADGEN_SEAMLESS_VIDEO_XL = 'LEADGEN_SEAMLESS_VIDEO_XL',

  SEAMLESS_CAROUSEL = 'SEAMLESS_CAROUSEL',
  NATIVE = 'NATIVE',
  ADOPTIMIZE_CLICKS = 'ADOPTIMIZE_CLICKS',
  DEALS = 'DEALS',
  LISTING_AD = 'LISTING_AD',
  OCCASIONS_LISTER_AD = 'OCCASIONS_LISTER_AD',

  BRANDED_CONTENT = 'BRANDED_CONTENT',
  // NL
  BRANDED_CONTENT_ADR = 'BRANDED_CONTENT_ADR',
  BRANDED_CONTENT_NU_NL = 'BRANDED_CONTENT_NU_NL',
  BRANDED_CONTENT_VTP = 'BRANDED_CONTENT_VTP',
  BRANDED_CONTENT_LMF = 'BRANDED_CONTENT_LMF',
  // BEL
  BRANDED_CONTENT_HLN = 'BRANDED_CONTENT_HLN',
  BRANDED_CONTENT_DE_MORGEN = 'BRANDED_CONTENT_DE_MORGEN',
  BRANDED_CONTENT_VIDEO = 'BRANDED_CONTENT_VIDEO',

  TWEAKERS_FEATURED_PRODUCT = 'TWEAKERS_FEATURED_PRODUCT',
  TWEAKERS_FEATURED_SHOP = 'TWEAKERS_FEATURED_SHOP',
  TWEAKERS_ADVERTORIAL = 'TWEAKERS_ADVERTORIAL',
  FRAMED_VIDEO = 'FRAMED_VIDEO',
  TWEAKERS_FEATURED_DEAL = 'TWEAKERS_FEATURED_DEAL',
}

export enum COUNTRY {
  NL = 'NL',
  BE = 'BE',
}
